import { Component, Input, OnInit } from '@angular/core';
import { StockListNews } from '../services/stockdata.service';

@Component({
  selector: 'app-stock-news-preview',
  templateUrl: './stock-news-preview.component.html',
  styleUrls: ['./stock-news-preview.component.scss'],
})
export class StockNewsPreviewComponent implements OnInit {

  @Input() news: StockListNews;
  @Input() ticker: string;
  withImages: boolean = false;

  constructor() { }

  ngOnInit() {
    // this.withImages = true;
    if (this.withImages) {
      const link = `http://localhost:8080/${this.news.link}`;
      this.getNewsImages(link).then((res: any) => {
        console.log('newsSrc', res.newsSrc)
        console.log('logoSrc', res.logoSrc)
        this.news.newsSrc = res.newsSrc;
        this.news.logoSrc = res.logoSrc;
        this.withImages = true;
      });
    }
  }

  openNews(): void {
    window.open(this.news.link, "_blank");
  }

  getNewsImages(uri) {
    return new Promise((res, rej) => {
      fetch(uri)
      .then(res => {
        return res.text();
      })
      .then(data => {
        document.getElementById('news-test').innerHTML = data;
        const logoSrc = (document.querySelector("article > div.caas-logo > a > img") as any)?.src;
  
        let newsSrc = (document.querySelector(".caas-figure-with-pb > div > div > img") as any)?.src;

        if (newsSrc?.includes(location.origin)) {
          console.log(newsSrc)
          newsSrc = null;
        }

        document.getElementById('news-test').innerHTML = '';
        res({logoSrc, newsSrc});
      });
    });
  }

}
