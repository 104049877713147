import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface StockListQuote {
  exchange: string;
  shortname: string;
  quoteType: string;
  symbol: string;
  index: string;
  score: number;
  typeDisp: string;
  longname: string;
  isYahooFinance: boolean;
  regularMarketPrice?: any;
  previousClose?: any;
  coinImageUrl?: any;
  logo?: any;
  coindata?: any;
}
export interface StockListNews {
  uuid: string;
  title: string;
  publisher: string;
  link: string;
  providerPublishTime: number;
  type: string;
  newsSrc?: string;
  logoSrc?: string;
}
export interface StockList {
  quotes: StockListQuote[];
  news: StockListNews[];
}

@Injectable({
  providedIn: 'root'
})
export class StockDataService {
  // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjA4ZDEwODAyNmJhZGExYWNjODgyNTljIn0sImlhdCI6MTYxOTg1Nzc5NiwiZXhwIjoxNjE5OTAwOTk2fQ.YQzXIEjcnBJ94u5kIKDs_dFpiW3ysL600c51nxNXXFg';
  proxyUrl: string = environment.proxyUrl;
  backUrl: string = environment.backUrl;
  apiUrl: string = environment.apiUrl;
  gatewayUrl: string = environment.gatewayUrl;

  constructor(private http: HttpClient) { }

  // get trending top 50 for the united states
  // https://query2.finance.yahoo.com/v1/finance/trending/US?count=5

  // fetch images by query search
  // https://pixabay.com/api/?key=21773405-609d740ac2facad5d4c2ec8bf&q=tesla&image_type=photo

  // get stock logos and info
  // https://query1.finance.yahoo.com/v7/finance/quote?lang=en-US&region=US&corsDomain=finance.yahoo.com&symbols=FB,TSLA,BNB-USD

  // get historical times info
  // https://query1.finance.yahoo.com/v7/finance/spark?symbols=%5EGSPC&range=1d&interval=5m&indicators=close&includeTimestamps=false&includePrePost=false&corsDomain=finance.yahoo.com&.tsrc=finance
  
  // get stock info
  // https://query1.finance.yahoo.com/v10/finance/quoteSummary/NVDA?formatted=true&crumb=35iduAAyktu&lang=en-US&region=US&modules=price%2CsummaryDetail%2CpageViews%2CfinancialsTemplate&corsDomain=finance.yahoo.com

  getHistoricalTime(ticker: string, range?: string, interval?: string): Observable<any> {
    if (!range) {
      range = '1d';
    }
    if (!interval) {
      interval = '5m';
    }
    let url = `${this.gatewayUrl}historicaltime/symbols=${ticker}&range=${range}&interval=${interval}&indicators=close&includeTimestamps=false&includePrePost=false&corsDomain=finance.yahoo.com&.tsrc=finance`;
    return this.http.get(url, {responseType: 'text'});
  }

  // Stock info with logo from  https://api.polygon.io/v1/meta/symbols/TSLA/company?&apiKey=pAcY2RqoVQoyvleix98sB3kEQ5I3B2jD
  getStockInfoWithLogo(ticker: string): Observable<any> {
    let url = `https://api.polygon.io/v1/meta/symbols/${ticker}/company?&apiKey=pAcY2RqoVQoyvleix98sB3kEQ5I3B2jD`;
    return this.http.get<any>(url);
  }

  getV7StockInfoWithLogo(ticker: string): Observable<any> {
    let url = `${this.proxyUrl}https://query1.finance.yahoo.com/v7/finance/quote?lang=en-US&region=US&corsDomain=finance.yahoo.com&symbols=${ticker}`;
    return this.http.get<any>(url);
  }

  getCoinmarketcapStockInfoWithLogo(ticker: string): Observable<any> {
    let url = `${this.gatewayUrl}coindata/symbol=${ticker}`;
    return this.http.get<any>(url);
  }

  // also works to get stock news
  getFavourites(): Observable<any> {
    let url = `${this.apiUrl}/favourites`;
    return this.http.get<any>(url);
  } 

  toggleFavourites(ticker: string, action: string): Observable<any> {
    let url = `${this.apiUrl}/favourites/${ticker}/${action}`;
    return this.http.get<any>(url);
  }

  getStockList(search: string): Observable<any> {
    let url = `${this.gatewayUrl}stocklist/q=${search}&lang=en-US&region=US&quotesCount=6&newsCount=40&enableFuzzyQuery=false&quotesQueryId=tss_match_phrase_query&multiQuoteQueryId=multi_quote_single_token_query&newsQueryId=news_cie_vespa&enableCb=true&enableNavLinks=true&enableEnhancedTrivialQuery=true`
    return this.http.get<any>(url);
  }

  getHistoricalData(ticker: string, timestampStart: number, timestampEnd: number): Observable<any> {
    let url = `${this.gatewayUrl}stockhistory/${ticker}queryparams=period1=${timestampStart}&period2=${timestampEnd}&interval=1d&events=history&includeAdjustedClose=true`;
    return this.http.get(url, { responseType: 'text' });
  }

  // deprecated
  // searchStockList(tickerName: string): Observable<StockList> {
  //   // now returns an Observable of Config
  //   let url = `https://apidojo-yahoo-finance-v1.p.rapidapi.com/auto-complete?q=${tickerName}&region=US`
  //   return this.http.get<StockList>(url, { headers: {
  //     "x-rapidapi-key": "43abe3d96fmsh8eb500aca74cf09p1955ddjsn64355d325c51",
  //     "x-rapidapi-host": "apidojo-yahoo-finance-v1.p.rapidapi.com",
  //   }});
  // }

  getRandomStockTicker(): string {
    const stocks = [
      ['2U', 'TWOU'],
      ['3M', 'MMM'],
      ['Abbott Laboratories', 'ABT'],
      ['AbbVie Inc.', 'ABBV'],
      ['Abiomed', 'ABMD'],
      ['Acadia Healthcare', 'ACHC'],
      ['Accenture', 'ACN'],
      ['Activision Blizzard', 'ATVI'],
      ['Acuity Brands', 'AYI'],
      ['Adient', 'ADNT'],
      ['Adobe Inc.', 'ADBE'],
      ['ADT Inc.', 'ADT'],
      ['Advance Auto Parts', 'AAP'],
      ['Advanced Micro Devices', 'AMD'],
      ['AECOM', 'ACM'],
      ['AES Corporation', 'AES'],
      ['Affiliated Managers Group', 'AMG'],
      ['Aflac', 'AFL'],
      ['AGCO', 'AGCO'],
      ['Agilent Technologies', 'A'],
      ['Agios Pharmaceuticals', 'AGIO'],
      ['AGNC Investment Corp.', 'AGNC'],
      ['Air Lease Corporation', 'AL'],
      ['Air Products & Chemicals', 'APD'],
      ['Akamai Technologies', 'AKAM'],
      ['Alaska Air Group', 'ALK'],
      ['Albemarle Corporation', 'ALB'],
      ['Alcoa', 'AA'],
      ['Alexandria Real Estate Equities', 'ARE'],
      ['Alexion Pharmaceuticals', 'ALXN'],
      ['Align Technology', 'ALGN'],
      ['Alkermes', 'ALKS'],
      ['Alleghany Corporation', 'Y'],
      ['Allegion', 'ALLE'],
      ['Alliance Data Systems', 'ADS'],
      ['Alliant Energy', 'LNT'],
      ['Allison Transmission', 'ALSN'],
      ['Allstate', 'ALL'],
      ['Ally Financial', 'ALLY'],
      ['Alnylam Pharmaceuticals', 'ALNY'],
      ['Alphabet Inc. (Class A)', 'GOOGL'],
      ['Alphabet Inc. (Class C)', 'GOOG'],
      ['Altria', 'MO'],
      ['Amazon', 'AMZN'],
      ['AMC Networks', 'AMCX'],
      ['Amdocs', 'DOX'],
      ['AMERCO', 'UHAL'],
      ['Ameren', 'AEE'],
      ['American Airlines', 'AAL'],
      ['American Campus Communities', 'ACC'],
      ['American Electric Power', 'AEP'],
      ['American Express', 'AXP'],
      ['American Financial Group', 'AFG'],
      ['American Homes 4 Rent', 'AMH'],
      ['American International Group', 'AIG'],
      ['American National Insurance Company', 'ANAT'],
      ['American Tower', 'AMT'],
      ['American Water Works', 'AWK'],
      ['Ameriprise Financial', 'AMP'],
      ['AmerisourceBergen', 'ABC'],
      ['Ametek', 'AME'],
      ['Amgen', 'AMGN'],
      ['Amphenol', 'APH'],
      ['Analog Devices', 'ADI'],
      ['Annaly Capital Management', 'NLY'],
      ['Ansys', 'ANSS'],
      ['Antero Resources', 'AR'],
      ['Anthem', 'ANTM'],
      ['AON', 'AON'],
      ['APA Corporation', 'APA'],
      ['Aimco', 'AIV'],
      ['Apple Hospitality Reit', 'APLE'],
      ['Apple Inc.', 'AAPL'],
      ['Applied Materials', 'AMAT'],
      ['Aptargroup', 'ATR'],
      ['Aptiv', 'APTV'],
      ['Essential Utilities', 'WTRG'],
      ['Aramark', 'ARMK'],
      ['Arch Capital Group', 'ACGL'],
      ['Archer Daniels Midland', 'ADM'],
      ['Ardagh Group', 'ARD'],
      ['Arista Networks', 'ANET'],
      ['Armstrong World Industries', 'AWI'],
      ['Arrow Electronics', 'ARW'],
      ['Ashland Inc.', 'ASH'],
      ['Aspen Technology', 'AZPN'],
      ['Associated Banc-Corp', 'ASB'],
      ['Assurant', 'AIZ'],
      ['Assured Guaranty', 'AGO'],
      ['AT&T', 'T'],
      ['Athene Holding', 'ATH'],
      ['Atlassian', 'TEAM'],
      ['Atmos Energy', 'ATO'],
      ['Autodesk', 'ADSK'],
      ['Automatic Data Processing', 'ADP'],
      ['AutoNation', 'AN'],
      ['AutoZone', 'AZO'],
      ['AvalonBay Communities', 'AVB'],
      ['Avangrid', 'AGR'],
      ['Avery Dennison', 'AVY'],
      ['Avnet', 'AVT'],
      ['AXA Equitable Holdings', 'EQH'],
      ['Axalta Coating Systems', 'AXTA'],
      ['AXIS Capital', 'AXS'],
      ['Baker Hughes', 'BKR'],
      ['Ball Corporation', 'BLL'],
      ['Bank of America', 'BAC'],
      ['Bank of Hawaii', 'BOH'],
      ['Bank of New York Mellon', 'BK'],
      ['Bank OZK', 'OZK'],
      ['BankUnited', 'BKU'],
      ['Baxter International', 'BAX'],
      ['Becton Dickinson', 'BDX'],
      ['W. R. Berkley Corporation', 'WRB'],
      ['Berkshire Hathaway', 'BRK.B'],
      ['Berry Global', 'BERY'],
      ['Best Buy', 'BBY'],
      ['Beyond Meat', 'BYND'],
      ['BGC Partners', 'BGCP'],
      ['Biogen', 'BIIB'],
      ['BioMarin Pharmaceutical', 'BMRN'],
      ['Bio-Rad Laboratories', 'BIO'],
      ['Bio-Techne', 'TECH'],
      ['Black Knight', 'BKI'],
      ['BlackRock', 'BLK'],
      ['H&R Block', 'HRB'],
      ['Bluebird Bio', 'BLUE'],
      ['Boeing', 'BA'],
      ['BOK Financial Corporation', 'BOKF'],
      ['Booking Holdings', 'BKNG'],
      ['Booz Allen Hamilton', 'BAH'],
      ['BorgWarner', 'BWA'],
      ['Boston Scientific', 'BSX'],
      ['Brandywine Realty Trust', 'BDN'],
      ['Bright Horizons Family Solutions', 'BFAM'],
      ['Brighthouse Financial', 'BHF'],
      ['Bristol-Myers Squibb', 'BMY'],
      ['Brixmor Property Group', 'BRX'],
      ['Broadcom Corporation', 'AVGO'],
      ['Broadridge Financial Solutions', 'BR'],
      ['Brookfield Properties Retail Group', 'BPYU'],
      ['Brown & Brown', 'BRO'],
      ['Brown–Forman (Class A)', 'BF.A'],
      ['Brown–Forman (Class B)', 'BF.B'],
      ['Bruker', 'BRKR'],
      ['Brunswick', 'BC'],
      ['Bunge Limited', 'BG'],
      ['Burlington Stores', 'BURL'],
      ['BWX Technologies', 'BWXT'],
      ['C. H. Robinson', 'CHRW'],
      ['Cable One', 'CABO'],
      ['Cabot Corporation', 'CBT'],
      ['Cabot Oil & Gas', 'COG'],
      ['CACI', 'CACI'],
      ['Cadence Design Systems', 'CDNS'],
      ['Caesars Entertainment', 'CZR'],
      ['Camden Property Trust', 'CPT'],
      ['Campbell Soup', 'CPB'],
      ['Cantel Medical Corporation', 'CMD'],
      ['Capital One Financial', 'COF'],
      ['Cardinal Health', 'CAH'],
      ['Carlisle Companies', 'CSL'],
      ['CarMax', 'KMX'],
      ['Carnival', 'CCL'],
      ['Carrier Global', 'CARR'],
      ['Carter\'s', 'CRI'],
      ['Casey\'s General Stores', 'CASY'],
      ['Catalent', 'CTLT'],
      ['Caterpillar', 'CAT'],
      ['Cboe Global Markets', 'CBOE'],
      ['CBRE Group', 'CBRE'],
      ['CDK Global', 'CDK'],
      ['CDW Corporation', 'CDW'],
      ['Celanese', 'CE'],
      ['Centene', 'CNC'],
      ['Centennial Resource Development', 'CDEV'],
      ['Centerpoint Energy', 'CNP'],
      ['Ceridian', 'CDAY'],
      ['Boston Properties', 'BXP'],
      ['CF Industries Holdings', 'CF'],
      ['Charles River Laboratories', 'CRL'],
      ['Charter Communications', 'CHTR'],
      ['Chemed Corporation', 'CHE'],
      ['Cheniere Energy', 'LNG'],
      ['Chesapeake Energy', 'CHK'],
      ['Chevron Corporation', 'CVX'],
      ['Chimera Investment', 'CIM'],
      ['Chipotle Mexican Grill', 'CMG'],
      ['Choice Hotels', 'CHH'],
      ['Chubb Limited', 'CB'],
      ['Church & Dwight', 'CHD'],
      ['Cigna', 'CI'],
      ['Cimarex Energy', 'XEC'],
      ['Cincinnati Financial', 'CINF'],
      ['Cinemark Holdings', 'CNK'],
      ['Cintas', 'CTAS'],
      ['Cisco Systems', 'CSCO'],
      ['CIT Group', 'CIT'],
      ['Citigroup', 'C'],
      ['Citizens Financial Group', 'CFG'],
      ['Citrix Systems', 'CTXS'],
      ['Clean Harbors', 'CLH'],
      ['Clorox', 'CLX'],
      ['CME Group', 'CME'],
      ['CMS Energy', 'CMS'],
      ['CNA Financial', 'CNA'],
      ['CNX Resources', 'CNX'],
      ['The Coca-Cola Company', 'KO'],
      ['Cognex Corporation', 'CGNX'],
      ['Cognizant', 'CTSH'],
      ['Coherent, Inc.', 'COHR'],
      ['Colfax Corporation', 'CFX'],
      ['Colgate Palmolive', 'CL'],
      ['Colony Capital', 'CLNY'],
      ['Columbia Property Trust', 'CXP'],
      ['Columbia Sportswear', 'COLM'],
      ['Comcast (Class A)', 'CMCSA'],
      ['Comerica', 'CMA'],
      ['Commerce Bancshares', 'CBSH'],
      ['CommScope', 'COMM'],
      ['Conagra Brands', 'CAG'],
      ['Conduent', 'CNDT'],
      ['ConocoPhillips', 'COP'],
      ['Consolidated Edison', 'ED'],
      ['Constellation Brands', 'STZ'],
      ['Cerner', 'CERN'],
      ['Copa Holdings', 'CPA'],
      ['Copart', 'CPRT'],
      ['Corelogic', 'CLGX'],
      ['CoreSite', 'COR'],
      ['Corning Inc.', 'GLW'],
      ['Corporate Office Properties Trust', 'OFC'],
      ['CoStar Group', 'CSGP'],
      ['Costco Wholesale', 'COST'],
      ['Coty, Inc.', 'COTY'],
      ['Crane Co.', 'CR'],
      ['Credit Acceptance', 'CACC'],
      ['Crown Castle', 'CCI'],
      ['Crown Holdings', 'CCK'],
      ['CSX Corporation', 'CSX'],
      ['CubeSmart', 'CUBE'],
      ['Frost Bank', 'CFR'],
      ['Cummins', 'CMI'],
      ['Curtiss-Wright', 'CW'],
      ['CVS Health', 'CVS'],
      ['Cypress Semiconductor', 'CY'],
      ['CyrusOne', 'CONE'],
      ['D. R. Horton', 'DHI'],
      ['Danaher Corporation', 'DHR'],
      ['Darden Restaurants', 'DRI'],
      ['DaVita Inc.', 'DVA'],
      ['SITE Centers', 'SITC'],
      ['John Deere', 'DE'],
      ['Dell Technologies', 'DELL'],
      ['Delta Air Lines', 'DAL'],
      ['Dentsply Sirona', 'XRAY'],
      ['Devon Energy', 'DVN'],
      ['Dexcom', 'DXCM'],
      ['Diamondback Energy', 'FANG'],
      ['Dick\'s Sporting Goods', 'DKS'],
      ['Digital Realty Trust', 'DLR'],
      ['Discover Financial', 'DFS'],
      ['Discovery, Inc. (Series A)', 'DISCA'],
      ['Discovery, Inc. (Series C)', 'DISCK'],
      ['Dish Network', 'DISH'],
      ['The Walt Disney Company', 'DIS'],
      ['Diversified Healthcare Trust', 'DHC'],
      ['DocuSign', 'DOCU'],
      ['Dolby Laboratories', 'DLB'],
      ['Dollar General', 'DG'],
      ['Dollar Tree', 'DLTR'],
      ['Dominion Energy', 'D'],
      ['Domino\'s Pizza', 'DPZ'],
      ['Continental Resources', 'CLR'],
      ['The Cooper Companies', 'COO'],
      ['Douglas Emmett', 'DEI'],
      ['Dover Corporation', 'DOV'],
      ['DuPont', 'DD'],
      ['DTE Energy', 'DTE'],
      ['Duke Energy', 'DUK'],
      ['Duke Realty', 'DRE'],
      ['Dun & Bradstreet', 'DNB'],
      ['DXC Technology', 'DXC'],
      ['Eagle Materials', 'EXP'],
      ['East West Bancorp', 'EWBC'],
      ['Eastman Chemical', 'EMN'],
      ['Eaton Corporation', 'ETN'],
      ['Eaton Vance', 'EV'],
      ['eBay', 'EBAY'],
      ['EchoStar', 'SATS'],
      ['Ecolab', 'ECL'],
      ['Edison International', 'EIX'],
      ['Edwards Lifesciences', 'EW'],
      ['Electronic Arts', 'EA'],
      ['Emerson Electric', 'EMR'],
      ['Empire State Realty', 'ESRT'],
      ['Encompass Health', 'EHC'],
      ['Energen', 'EGN'],
      ['Energizer Holdings', 'ENR'],
      ['Entergy', 'ETR'],
      ['Envision Healthcare', 'EVHC'],
      ['EOG Resources', 'EOG'],
      ['EPAM Systems', 'EPAM'],
      ['EPR Properties', 'EPR'],
      ['EQT Corporation', 'EQT'],
      ['Equifax', 'EFX'],
      ['Equinix', 'EQIX'],
      ['Equity Commonwealth', 'EQC'],
      ['Equity Lifestyle Properties', 'ELS'],
      ['Equity Residential', 'EQR'],
      ['Erie Indemnity', 'ERIE'],
      ['Essex Property Trust', 'ESS'],
      ['Estee Lauder Companies', 'EL'],
      ['Euronet Worldwide', 'EEFT'],
      ['Everbridge', 'EVBG'],
      ['Evercore', 'EVR'],
      ['Everest Re', 'RE'],
      ['Evergy', 'EVRG'],
      ['Eversource Energy', 'ES'],
      ['Domtar Corporation', 'UFS'],
      ['Donaldson Company', 'DCI'],
      ['Expedia Group', 'EXPE'],
      ['Expeditors International', 'EXPD'],
      ['Extended Stay America', 'STAY'],
      ['Extra Space Storage', 'EXR'],
      ['Extraction Oil & Gas', 'XOG'],
      ['ExxonMobil', 'XOM'],
      ['F5 Networks', 'FFIV'],
      ['Facebook', 'FB'],
      ['FactSet', 'FDS'],
      ['Fair Isaac', 'FICO'],
      ['Fastenal', 'FAST'],
      ['Federal Realty Investment Trust', 'FRT'],
      ['FedEx', 'FDX'],
      ['FIS', 'FIS'],
      ['Fifth Third Bancorp', 'FITB'],
      ['FireEye', 'FEYE'],
      ['First American Corporation', 'FAF'],
      ['First Citizens BancShares', 'FCNCA'],
      ['First Data Corporation', 'FDC'],
      ['First Hawaiian Bank', 'FHB'],
      ['First Horizon National Corporation', 'FHN'],
      ['First Republic Bank', 'FRC'],
      ['First Solar', 'FSLR'],
      ['FirstEnergy', 'FE'],
      ['Fiserv', 'FISV'],
      ['Fleetcor', 'FLT'],
      ['Flir Systems', 'FLIR'],
      ['Floor & Decor', 'FND'],
      ['Flowers Foods', 'FLO'],
      ['Flowserve', 'FLS'],
      ['Fluor Corporation', 'FLR'],
      ['FMC Corporation', 'FMC'],
      ['FNB Corporation', 'FNB'],
      ['Fidelity National Financial', 'FNF'],
      ['Foot Locker', 'FL'],
      ['Ford', 'F'],
      ['Fortinet', 'FTNT'],
      ['Fortive Corporation', 'FTV'],
      ['Fortune Brands Home & Security', 'FBHS'],
      ['Fox Corporation (Class A)', 'FOXA'],
      ['Fox Corporation (Class B)', 'FOX'],
      ['Franklin Resources', 'BEN'],
      ['Freeport-McMoRan', 'FCX'],
      ['Arthur J. Gallagher & Co.', 'AJG'],
      ['Gaming and Leisure Properties', 'GLPI'],
      ['Gap Inc.', 'GPS'],
      ['Exact Sciences', 'EXAS'],
      ['Exelixis', 'EXEL'],
      ['Exelon', 'EXC'],
      ['Gates Corporation', 'GTES'],
      ['GCI Liberty', 'GLIBA'],
      ['General Dynamics', 'GD'],
      ['General Electric', 'GE'],
      ['General Mills', 'GIS'],
      ['General Motors', 'GM'],
      ['Genesee & Wyoming', 'GWR'],
      ['Genpact', 'G'],
      ['Gentex', 'GNTX'],
      ['Genuine Parts', 'GPC'],
      ['Gilead Sciences', 'GILD'],
      ['Global Payments', 'GPN'],
      ['Globe Life Inc.', 'GL'],
      ['GoDaddy', 'GDDY'],
      ['Goldman Sachs', 'GS'],
      ['Goodyear Tire & Rubber', 'GT'],
      ['W. R. Grace and Company', 'GRA'],
      ['Graco', 'GGG'],
      ['GrafTech', 'EAF'],
      ['Graham Holdings Company', 'GHC'],
      ['W. W. Grainger', 'GWW'],
      ['Grand Canyon Education', 'LOPE'],
      ['Graphic Packaging', 'GPK'],
      ['Grubhub', 'GRUB'],
      ['Guidewire Software', 'GWRE'],
      ['Hain Celestial', 'HAIN'],
      ['Halliburton', 'HAL'],
      ['Hanesbrands', 'HBI'],
      ['Hanover Insurance', 'THG'],
      ['Harley Davidson', 'HOG'],
      ['The Hartford', 'HIG'],
      ['Hasbro', 'HAS'],
      ['Hawaiian Electric Industries', 'HE'],
      ['HCA Healthcare', 'HCA'],
      ['Healthcare Trust America', 'HTA'],
      ['Healthpeak Properties', 'PEAK'],
      ['HEICO (Class A)', 'HEI.A'],
      ['HEICO Common', 'HEI'],
      ['Helmerich & Payne', 'HP'],
      ['Jack Henry & Associates', 'JKHY'],
      ['Herbalife Nutrition', 'HLF'],
      ['Hershey Company', 'HSY'],
      ['Hess Corporation', 'HES'],
      ['Gardner Denver', 'GDI'],
      ['Garmin', 'GRMN'],
      ['Gartner', 'IT'],
      ['Hilton Grand Vacations', 'HGV'],
      ['Hilton Worldwide', 'HLT'],
      ['HollyFrontier', 'HFC'],
      ['Hologic', 'HOLX'],
      ['Home Depot', 'HD'],
      ['Honeywell International', 'HON'],
      ['Hormel Foods', 'HRL'],
      ['Host Hotels & Resorts', 'HST'],
      ['Howard Hughes Corporation', 'HHC'],
      ['HP Inc.', 'HPQ'],
      ['Hubbell Incorporated', 'HUBB'],
      ['Hudson Pacific Properties', 'HPP'],
      ['Humana', 'HUM'],
      ['Huntington Bancshares', 'HBAN'],
      ['Huntington Ingalls Industries', 'HII'],
      ['Huntsman Corporation', 'HUN'],
      ['Hyatt Hotels', 'H'],
      ['IAC', 'IAC'],
      ['ICU Medical', 'ICUI'],
      ['IDEX Corporation', 'IEX'],
      ['Idexx Laboratories', 'IDXX'],
      ['IHS Markit', 'INFO'],
      ['Illinois Tool Works', 'ITW'],
      ['Illumina', 'ILMN'],
      ['Incyte', 'INCY'],
      ['Ingersoll-Rand', 'IR'],
      ['Ingredion', 'INGR'],
      ['Insulet', 'PODD'],
      ['Integra Lifesciences', 'IART'],
      ['Intel', 'INTC'],
      ['Interactive Brokers', 'IBKR'],
      ['Intercontinental Exchange', 'ICE'],
      ['International Game Technology', 'IGT'],
      ['International Paper', 'IP'],
      ['The Interpublic Group of Companies', 'IPG'],
      ['IBM', 'IBM'],
      ['International Flavors & Fragrances', 'IFF'],
      ['Intuit', 'INTU'],
      ['Intuitive Surgical', 'ISRG'],
      ['Invesco', 'IVZ'],
      ['Invitation Homes', 'INVH'],
      ['Ionis Pharmaceuticals', 'IONS'],
      ['IPG Photonics', 'IPGP'],
      ['IQVIA', 'IQV'],
      ['Hewlett Packard Enterprise', 'HPE'],
      ['Hexcel', 'HXL'],
      ['Highwoods Properties', 'HIW'],
      ['Hill-Rom', 'HRC'],
      ['Jazz Pharmaceuticals', 'JAZZ'],
      ['J. B. Hunt', 'JBHT'],
      ['JBG Smith', 'JBGS'],
      ['Jefferies Financial Group', 'JEF'],
      ['JetBlue', 'JBLU'],
      ['Johnson & Johnson', 'JNJ'],
      ['Johnson Controls', 'JCI'],
      ['JLL', 'JLL'],
      ['JPMorgan Chase', 'JPM'],
      ['Juniper Networks', 'JNPR'],
      ['Kansas City Southern', 'KSU'],
      ['Kar Auction Services', 'KAR'],
      ['Kellogg\'s', 'K'],
      ['Keurig Dr Pepper', 'KDP'],
      ['Keycorp', 'KEY'],
      ['Keysight Technologies', 'KEYS'],
      ['Kilroy Realty', 'KRC'],
      ['Kimberly Clark', 'KMB'],
      ['Kimco Realty', 'KIM'],
      ['Kinder Morgan', 'KMI'],
      ['Kirby Corporation', 'KEX'],
      ['KLA Corporation', 'KLAC'],
      ['Knight-Swift', 'KNX'],
      ['Kohl\'s', 'KSS'],
      ['Kosmos Energy', 'KOS'],
      ['Kroger', 'KR'],
      ['L Brands', 'LB'],
      ['L3Harris Technologies', 'LHX'],
      ['LabCorp', 'LH'],
      ['Lam Research', 'LRCX'],
      ['Lamar Advertising', 'LAMR'],
      ['Lamb Weston', 'LW'],
      ['Landstar System', 'LSTR'],
      ['Las Vegas Sands', 'LVS'],
      ['Lazard', 'LAZ'],
      ['Lear Corporation', 'LEA'],
      ['Legg Mason', 'LM'],
      ['Leggett & Platt', 'LEG'],
      ['Leidos Holdings', 'LDOS'],
      ['Lennar (Class A)', 'LEN'],
      ['Lennar (Class B)', 'LEN.B'],
      ['Lennox International', 'LII'],
      ['Liberty Broadband (Series A)', 'LBRDA'],
      ['Liberty Broadband (Series C)', 'LBRDK'],
      ['Liberty Formula 1 (Series A)', 'FWONA'],
      ['Iron Mountain', 'IRM'],
      ['ITT Inc.', 'ITT'],
      ['Jabil', 'JBL'],
      ['Jacobs Engineering Group', 'JEC'],
      ['Eli Lilly and Company', 'LLY'],
      ['Lincoln Electric', 'LECO'],
      ['Lincoln National Corporation', 'LNC'],
      ['Lionsgate (Class A)', 'LGF.A'],
      ['Lionsgate (Class B)', 'LGF.B'],
      ['Littelfuse', 'LFUS'],
      ['Live Nation Entertainment', 'LYV'],
      ['LKQ', 'LKQ'],
      ['Lockheed Martin', 'LMT'],
      ['Loews Corporation', 'L'],
      ['Lowe\'s', 'LOW'],
      ['LPL Financial', 'LPLA'],
      ['Lululemon Athletica', 'LULU'],
      ['Lumen Technologies', 'LUMN'],
      ['Lyft', 'LYFT'],
      ['LyondellBasell', 'LYB'],
      ['M&T Bank', 'MTB'],
      ['Macerich', 'MAC'],
      ['Macquarie Infrastructure Corporation', 'MIC'],
      ['Macy\'s', 'M'],
      ['Madison Square Garden', 'MSG'],
      ['Manhattan Associates', 'MANH'],
      ['ManpowerGroup', 'MAN'],
      ['Marathon Oil', 'MRO'],
      ['Marathon Petroleum', 'MPC'],
      ['Markel Corporation', 'MKL'],
      ['MarketAxess', 'MKTX'],
      ['Marriott International', 'MAR'],
      ['Marsh & McLennan Companies', 'MMC'],
      ['Martin Marietta', 'MLM'],
      ['Marvell Technology', 'MRVL'],
      ['Masco', 'MAS'],
      ['Masimo', 'MASI'],
      ['Mastercard', 'MA'],
      ['Match Group', 'MTCH'],
      ['Mattel', 'MAT'],
      ['Maxim Integrated', 'MXIM'],
      ['McCormick & Company', 'MKC'],
      ['McDonald\'s', 'MCD'],
      ['McKesson', 'MCK'],
      ['MDU Resources', 'MDU'],
      ['Medical Properties Trust', 'MPW'],
      ['Mednax', 'MD'],
      ['Medtronic', 'MDT'],
      ['Merck & Co', 'MRK'],
      ['Liberty Formula 1 (Series C)', 'FWONK'],
      ['Liberty Property Trust', 'LPT'],
      ['Liberty Sirius XM (Series A)', 'LSXMA'],
      ['Liberty Sirius XM (Series C)', 'LSXMK'],
      ['Life Storage', 'LSI'],
      ['Capri Holdings', 'CPRI'],
      ['The Michaels Companies', 'MIK'],
      ['Microchip Technology', 'MCHP'],
      ['Micron Technology', 'MU'],
      ['Microsoft', 'MSFT'],
      ['Mid-America Apartment Communities', 'MAA'],
      ['Middleby Corporation', 'MIDD'],
      ['MKS Instruments', 'MKSI'],
      ['Mohawk Industries', 'MHK'],
      ['Molina Healthcare', 'MOH'],
      ['Molson Coors Brewing', 'TAP'],
      ['Mondelez International', 'MDLZ'],
      ['Monolithic Power Systems', 'MPWR'],
      ['Monster Beverage', 'MNST'],
      ['Moody\'s Corporation', 'MCO'],
      ['Morgan Stanley', 'MS'],
      ['Morningstar, Inc.', 'MORN'],
      ['The Mosaic Company', 'MOS'],
      ['Motorola Solutions', 'MSI'],
      ['MSC Industrial Direct', 'MSM'],
      ['MSCI', 'MSCI'],
      ['Murphy Oil', 'MUR'],
      ['Mylan', 'MYL'],
      ['Nabors Industries', 'NBR'],
      ['Nasdaq, Inc.', 'NDAQ'],
      ['National Fuel Gas', 'NFG'],
      ['National Instruments', 'NATI'],
      ['National Oilwell Varco', 'NOV'],
      ['National Retail Properties', 'NNN'],
      ['Navient Corporation', 'NAVI'],
      ['NCR Corporation', 'NCR'],
      ['Nektar Therapeutics', 'NKTR'],
      ['Netapp', 'NTAP'],
      ['Netflix', 'NFLX'],
      ['Neurocrine Biosciences', 'NBIX'],
      ['New Residential Invt', 'NRZ'],
      ['New York Community Bank', 'NYCB'],
      ['Newell Brands', 'NWL'],
      ['Newmarket Corporation', 'NEU'],
      ['Newmont Mining', 'NEM'],
      ['News Corp (Class A)', 'NWSA'],
      ['News Corp (Class B)', 'NWS'],
      ['Mercury General', 'MCY'],
      ['Metlife', 'MET'],
      ['Mettler Toledo', 'MTD'],
      ['MFA Financial', 'MFA'],
      ['MGM Resorts International', 'MGM'],
      ['Nordstrom', 'JWN'],
      ['Norfolk Southern Railway', 'NSC'],
      ['Northern Trust', 'NTRS'],
      ['Northrop Grumman', 'NOC'],
      ['NortonLifeLock', 'NLOK'],
      ['Norwegian Cruise Line', 'NCLH'],
      ['NRG Energy', 'NRG'],
      ['Nu Skin Enterprises', 'NUS'],
      ['Nuance Communications', 'NUAN'],
      ['Nucor', 'NUE'],
      ['Nutanix', 'NTNX'],
      ['Nvent Electric', 'NVT'],
      ['Nvidia', 'NVDA'],
      ['NVR, Inc.', 'NVR'],
      ['NXP Semiconductors', 'NXPI'],
      ['O\'Reilly Auto Parts', 'ORLY'],
      ['Occidental Petroleum', 'OXY'],
      ['Oklahoma Gas & Electric', 'OGE'],
      ['Okta, Inc.', 'OKTA'],
      ['Old Dominion Freight Line', 'ODFL'],
      ['Old Republic International', 'ORI'],
      ['Olin Corporation', 'OLN'],
      ['Omega Healthcare Investors', 'OHI'],
      ['Omnicom Group', 'OMC'],
      ['ON Semiconductor', 'ON'],
      ['OneMain Financial', 'OMF'],
      ['Oneok', 'OKE'],
      ['Oracle Corporation', 'ORCL'],
      ['Oshkosh Corporation', 'OSK'],
      ['Otis Worldwide', 'OTIS'],
      ['Outfront Media', 'OUT'],
      ['Owens Corning', 'OC'],
      ['Owens Illinois', 'OI'],
      ['Paccar', 'PCAR'],
      ['Packaging Corporation of America', 'PKG'],
      ['Pacwest Bancorp', 'PACW'],
      ['Palo Alto Networks', 'PANW'],
      ['Paramount Group', 'PGRE'],
      ['Park Hotels & Resorts', 'PK'],
      ['Parker Hannifin', 'PH'],
      ['Parsley Energy', 'PE'],
      ['Patterson-UTI', 'PTEN'],
      ['Paychex', 'PAYX'],
      ['Paycom', 'PAYC'],
      ['PayPal', 'PYPL'],
      ['NextEra Energy', 'NEE'],
      ['Nielsen Holdings', 'NLSN'],
      ['Nike, Inc.', 'NKE'],
      ['Nisource', 'NI'],
      ['Nordson Corporation', 'NDSN'],
      ['PepsiCo', 'PEP'],
      ['PerkinElmer', 'PKI'],
      ['Perrigo', 'PRGO'],
      ['Pfizer', 'PFE'],
      ['PG&E', 'PCG'],
      ['Philip Morris International', 'PM'],
      ['Phillips 66', 'PSX'],
      ['Pilgrim\'s Pride', 'PPC'],
      ['Pinnacle Financial Partners', 'PNFP'],
      ['Pinnacle Foods', 'PF'],
      ['Pinnacle West Capital', 'PNW'],
      ['Pioneer Natural Resources', 'PXD'],
      ['Element Solutions', 'ESI'],
      ['PNC Financial Services', 'PNC'],
      ['Polaris Industries', 'PII'],
      ['Pool Corporation', 'POOL'],
      ['Popular, Inc.', 'BPOP'],
      ['Post Holdings', 'POST'],
      ['PPG Industries', 'PPG'],
      ['PPL', 'PPL'],
      ['PRA Health Sciences', 'PRAH'],
      ['Premier', 'PINC'],
      ['T. Rowe Price', 'TROW'],
      ['Principal Financial Group', 'PFG'],
      ['Procter & Gamble', 'PG'],
      ['Progressive Corporation', 'PGR'],
      ['Prologis', 'PLD'],
      ['Proofpoint, Inc.', 'PFPT'],
      ['Prosperity Bancshares', 'PB'],
      ['Prudential Financial', 'PRU'],
      ['PTC', 'PTC'],
      ['Public Storage', 'PSA'],
      ['Public Service Enterprise Group', 'PEG'],
      ['PulteGroup', 'PHM'],
      ['Pure Storage', 'PSTG'],
      ['PVH', 'PVH'],
      ['Qiagen', 'QGEN'],
      ['Qorvo', 'QRVO'],
      ['Qualcomm', 'QCOM'],
      ['Quanta Services', 'PWR'],
      ['PBF Energy', 'PBF'],
      ['Pegasystems', 'PEGA'],
      ['Penske Automotive Group', 'PAG'],
      ['Pentair', 'PNR'],
      ['Penumbra, Inc.', 'PEN'],
      ['People\'s United Financial', 'PBCT'],
      ['Realogy', 'RLGY'],
      ['RealPage', 'RP'],
      ['Realty Income', 'O'],
      ['Regal Beloit', 'RBC'],
      ['Regency Centers', 'REG'],
      ['Regeneron Pharmaceuticals', 'REGN'],
      ['Regions Financial', 'RF'],
      ['Reinsurance Group of America', 'RGA'],
      ['Reliance Steel & Aluminum Co.', 'RS'],
      ['RenaissanceRe', 'RNR'],
      ['Republic Services', 'RSG'],
      ['Resmed', 'RMD'],
      ['Retail Properties of America, Inc.', 'RPAI'],
      ['RingCentral', 'RNG'],
      ['Robert Half International', 'RHI'],
      ['Rockwell Automation', 'ROK'],
      ['Rollins, Inc.', 'ROL'],
      ['Roper Technologies', 'ROP'],
      ['Ross Stores', 'ROST'],
      ['Royal Caribbean Cruises', 'RCL'],
      ['Royal Gold', 'RGLD'],
      ['RPC, Inc.', 'RES'],
      ['RPM International', 'RPM'],
      ['RSP Permian', 'RSPP'],
      ['Ryder', 'R'],
      ['S&P Global', 'SPGI'],
      ['Sabre Corporation', 'SABR'],
      ['Sage Therapeutics', 'SAGE'],
      ['Salesforce.com', 'CRM'],
      ['Banco Santander', 'SC'],
      ['Sarepta Therapeutics', 'SRPT'],
      ['SBA Communications', 'SBAC'],
      ['Henry Schein', 'HSIC'],
      ['Schlumberger', 'SLB'],
      ['Schneider National', 'SNDR'],
      ['Charles Schwab Corporation', 'SCHW'],
      ['Scotts Miracle-Gro Company', 'SMG'],
      ['Seaboard Corporation', 'SEB'],
      ['Sealed Air', 'SEE'],
      ['Quest Diagnostics', 'DGX'],
      ['Qurate Retail Group', 'QRTEA'],
      ['Ralph Lauren Corporation', 'RL'],
      ['Range Resources', 'RRC'],
      ['Raymond James Financial', 'RJF'],
      ['Rayonier', 'RYN'],
      ['Raytheon Technologies', 'RTX'],
      ['ServiceNow', 'NOW'],
      ['Service Properties Trust', 'SVC'],
      ['Sherwin-Williams', 'SHW'],
      ['Signature Bank', 'SBNY'],
      ['Silgan Holdings', 'SLGN'],
      ['Simon Property Group', 'SPG'],
      ['Sirius XM Satellite Radio', 'SIRI'],
      ['Six Flags', 'SIX'],
      ['Skechers', 'SKX'],
      ['Skyworks Solutions', 'SWKS'],
      ['SL Green Realty', 'SLG'],
      ['Sallie Mae', 'SLM'],
      ['SM Energy', 'SM'],
      ['A. O. Smith', 'AOS'],
      ['The J.M. Smucker Company', 'SJM'],
      ['Snap-on', 'SNA'],
      ['Sonoco Products', 'SON'],
      ['Southern Airways Express', 'SO'],
      ['Southern Copper', 'SCCO'],
      ['Southwest Airlines', 'LUV'],
      ['Spectrum Brands', 'SPB'],
      ['Spirit Aerosystems', 'SPR'],
      ['Spirit Realty Capital', 'SRC'],
      ['Splunk', 'SPLK'],
      ['Sprouts Farmers Market', 'SFM'],
      ['Square, Inc.', 'SQ'],
      ['SS&C Technologies', 'SSNC'],
      ['Stanley Black & Decker', 'SWK'],
      ['Starbucks', 'SBUX'],
      ['Starwood Property Trust', 'STWD'],
      ['State Street Corporation', 'STT'],
      ['Steel Dynamics', 'STLD'],
      ['Stericycle', 'SRCL'],
      ['Steris', 'STE'],
      ['Sterling Bancorp', 'STL'],
      ['Store Capital', 'STOR'],
      ['Stryker', 'SYK'],
      ['Sun Communities', 'SUI'],
      ['Suntrust Banks', 'STI'],
      ['Silicon Valley Bank', 'SIVB'],
      ['Switch', 'SWCH'],
      ['Seagen Inc.', 'SGEN'],
      ['SEI Investments Company', 'SEIC'],
      ['Sempra Energy', 'SRE'],
      ['Sensata Technologies', 'ST'],
      ['Service Corporation International', 'SCI'],
      ['ServiceMaster', 'SERV'],
      ['Tapestry, Inc.', 'TPR'],
      ['Targa Resources', 'TRGP'],
      ['Target Corporation', 'TGT'],
      ['Taubman Centers', 'TCO'],
      ['TCF Financial', 'TCF'],
      ['Teladoc Health', 'TDOC'],
      ['Teledyne Technologies', 'TDY'],
      ['Teleflex', 'TFX'],
      ['Telephone & Data Systems', 'TDS'],
      ['Tempur Sealy International', 'TPX'],
      ['Teradata', 'TDC'],
      ['Teradyne', 'TER'],
      ['Terex', 'TEX'],
      ['Tesaro', 'TSRO'],
      ['Tesla, Inc.', 'TSLA'],
      ['Texas Capital Bancshares', 'TCBI'],
      ['Texas Instruments', 'TXN'],
      ['Textron', 'TXT'],
      ['TFS Financial Corporation', 'TFSL'],
      ['The Chemours Company', 'CC'],
      ['The Kraft Heinz Company', 'KHC'],
      ['The Wendy\'s Company', 'WEN'],
      ['Thermo Fisher Scientific', 'TMO'],
      ['Thor Industries', 'THO'],
      ['Timken Company', 'TKR'],
      ['TJX Companies', 'TJX'],
      ['Toll Brothers', 'TOL'],
      ['Toro', 'TTC'],
      ['Tractor Supply', 'TSCO'],
      ['Transdigm Group', 'TDG'],
      ['Transocean', 'RIG'],
      ['TransUnion', 'TRU'],
      ['The Travelers Companies', 'TRV'],
      ['TreeHouse Foods', 'THS'],
      ['Tribune Publishing Company', 'TPCO'],
      ['Trimble', 'TRMB'],
      ['Trinity Industries', 'TRN'],
      ['Tripadvisor', 'TRIP'],
      ['Synchrony Financial', 'SYF'],
      ['Synopsys', 'SNPS'],
      ['Synovus Financial', 'SNV'],
      ['Sysco', 'SYY'],
      ['Tableau Software', 'DATA'],
      ['Take-Two Interactive', 'TTWO'],
      ['T-Mobile US', 'TMUS'],
      ['Truist Financial', 'TFC'],
      ['Uber', 'UBER'],
      ['UGI Corporation', 'UGI'],
      ['Ulta Beauty', 'ULTA'],
      ['Ultimate Software', 'ULTI'],
      ['Umpqua Holdings', 'UMPQ'],
      ['Under Armour (Class A)', 'UAA'],
      ['Under Armour (Class C)', 'UA'],
      ['Union Pacific', 'UNP'],
      ['United Airlines Holdings', 'UAL'],
      ['United Parcel Service', 'UPS'],
      ['United Rentals', 'URI'],
      ['U.S. Cellular', 'USM'],
      ['United States Steel', 'X'],
      ['United Therapeutics', 'UTHR'],
      ['UnitedHealth Group', 'UNH'],
      ['Uniti Group', 'UNIT'],
      ['Univar Solutions', 'UNVR'],
      ['Universal Display Corporation', 'OLED'],
      ['Universal Health Services', 'UHS'],
      ['Unum', 'UNM'],
      ['Urban Outfitters', 'URBN'],
      ['US Bancorp', 'USB'],
      ['US Foods', 'USFD'],
      ['VF Corporation', 'VFC'],
      ['Vail Resorts', 'MTN'],
      ['Valero Energy', 'VLO'],
      ['Valmont Industries', 'VMI'],
      ['Valvoline', 'VVV'],
      ['Varian Medical Systems', 'VAR'],
      ['Vectren', 'VVC'],
      ['Veeva Systems', 'VEEV'],
      ['Ventas', 'VTR'],
      ['VEREIT', 'VER'],
      ['Verisign', 'VRSN'],
      ['Verisk Analytics', 'VRSK'],
      ['Verizon Communications', 'VZ'],
      ['Versum Materials', 'VSM'],
      ['Vertex Pharmaceuticals', 'VRTX'],
      ['ViacomCBS (Class A)', 'VIACA'],
      ['ViacomCBS (Class B)', 'VIAC'],
      ['Twilio', 'TWLO'],
      ['Twitter', 'TWTR'],
      ['Two Harbors Investment', 'TWO'],
      ['Tyler Technologies', 'TYL'],
      ['Tyson Foods', 'TSN'],
      ['USG Corporation', 'USG'],
      ['Ubiquiti Inc.', 'UI'],
      ['UDR, Inc.', 'UDR'],
      ['Vulcan Materials', 'VMC'],
      ['W. P. Carey', 'WPC'],
      ['WABCO Holdings', 'WBC'],
      ['Westinghouse Air Brake Technologies Corp', 'WAB'],
      ['Walgreens Boots Alliance', 'WBA'],
      ['Walmart', 'WMT'],
      ['Waste Management', 'WM'],
      ['Waters Corporation', 'WAT'],
      ['Watsco', 'WSO'],
      ['Wayfair', 'W'],
      ['Weatherford International', 'WFTLF'],
      ['Webster Bank', 'WBS'],
      ['WEC Energy Group', 'WEC'],
      ['Weingarten Realty', 'WRI'],
      ['Welbilt', 'WBT'],
      ['WellCare', 'WCG'],
      ['Wells Fargo', 'WFC'],
      ['Welltower', 'WELL'],
      ['Wesco International', 'WCC'],
      ['West Pharmaceutical Services', 'WST'],
      ['Western Alliance Bancorp', 'WAL'],
      ['Western Digital', 'WDC'],
      ['Western Union', 'WU'],
      ['Westlake Chemical', 'WLK'],
      ['Westrock', 'WRK'],
      ['Wex', 'WEX'],
      ['Weyerhaeuser', 'WY'],
      ['Whirlpool Corporation', 'WHR'],
      ['White Mountains Insurance Group', 'WTM'],
      ['Whiting Petroleum Corporation', 'WLL'],
      ['Wiley', 'JW.A'],
      ['Williams Companies', 'WMB'],
      ['Williams-Sonoma, Inc.', 'WSM'],
      ['Willis Towers Watson', 'WLTW'],
      ['Wintrust Financial', 'WTFC'],
      ['Workday, Inc.', 'WDAY'],
      ['Worldpay', 'WP'],
      ['Wyndham Destinations', 'WYND'],
      ['Wyndham Hotels & Resorts', 'WH'],
      ['Vici Properties', 'VICI'],
      ['Virtu Financial', 'VIRT'],
      ['Visa Inc.', 'V'],
      ['Visteon', 'VC'],
      ['Vistra Energy', 'VST'],
      ['VMware', 'VMW'],
      ['Vornado Realty Trust', 'VNO'],
      ['Voya Financial', 'VOYA'],
      ['Zayo Group', 'ZAYO'],
      ['Zebra Technologies', 'ZBRA'],
      ['Zendesk', 'ZEN'],
      ['Zillow (Class A)', 'ZG'],
      ['Zillow (Class C)', 'Z'],
      ['Zimmer Biomet', 'ZBH'],
      ['Zions Bancorporation', 'ZION'],
      ['Zoetis', 'ZTS'],
      ['Zynga', 'ZNGA'],
      ['Wynn Resorts', 'WYNN'],
      ['Xcel Energy', 'XEL'],
      ['Xerox', 'XRX'],
      ['Xilinx', 'XLNX'],
      ['XPO Logistics', 'XPO'],
      ['Xylem Inc.', 'XYL'],
      ['Yum China Holdings', 'YUMC'],
      ['Yum! Brands', 'YUM']
    ];

    return stocks[Math.floor(Math.random() * stocks.length)][1];
  }
}
