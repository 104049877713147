import { StockSimulationComponent } from './../stock-simulation/stock-simulation.component';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/dark-unica';
theme(Highcharts);
import * as sampleStockChartJSON from '../../assets/sample-stock-chart.json';
import { StockDataService } from '../services/stockdata.service';

@Component({
  selector: 'app-stock-analysis-details',
  templateUrl: './stock-analysis-details.component.html',
  styleUrls: ['./stock-analysis-details.component.scss'],
})
export class StockAnalysisDetailsComponent implements OnInit {

  @Input() processedData;

  Highcharts = Highcharts;
  chartOptions;
  stockPriceChartOptions;
  stockVolumeChartOptions;
  favourites: string[];
  isFavourite: boolean;

  positive = '72 163 117';
  negative = '194 10 10';
  chartColor;

  capitalInvested: number;

  constructor(
    private modalController: ModalController,
    private stockDataService: StockDataService,
  ) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser?.token) {

      this.stockDataService.getFavourites().subscribe((newFavourites) => {
        if (newFavourites?.list?.length) {
          this.renderFavourites(newFavourites.list);
        }
      });
    }
  }

  toggleFavourite() {
    console.log('toggleFavourite');
    if (!this.favourites) {
      this.favourites = [];
    }
    const ticker = this.processedData.selectedStock.symbol;
    let action;
    if (this.favourites.includes(ticker)) {
      action = 'remove';
    } else {
      action = 'add';
    }
    this.stockDataService.toggleFavourites(ticker, action).subscribe(_ => {
      this.stockDataService.getFavourites().subscribe((newFavourites) => {
        this.renderFavourites(newFavourites.list);
      });
    });
  }

  toggleCapitalInvested(invested: boolean): void {
    if (!invested) {
      if (this.capitalInvested === 1) {
        this.capitalInvested = null;
      } else {
        this.capitalInvested = 1;
      }
    }
    if (invested) {
      if (this.capitalInvested === 2) {
        this.capitalInvested = null;
      } else {
        this.capitalInvested = 2;
      }
    }

    this.chartOptions = {};
    setTimeout(() => {
      this.renderChart();
    });
  }

  renderFavourites(favourites) {
    this.favourites = favourites;
    console.log(this.favourites)
    if (this.favourites?.includes(this.processedData.selectedStock.symbol)) {
      this.isFavourite = true;
    } else {
      this.isFavourite = false;
    }
  }

  ngOnInit() {
    if (this.processedData.percentageYield > 0) {
      this.chartColor = this.positive;
    } else {
      this.chartColor = this.negative;
    }
    console.log(this.processedData);
    this.stockDataService.getStockList(this.processedData.selectedStock.symbol).subscribe((_res) => {
      this.processedData.selectedStock['news'] = _res.news;
    });
    this.renderChart();
    this.renderGenericChart('stockPriceChartOptions', 'Stock Price', this.processedData.dailyStockPrices)
    this.renderGenericChart('stockVolumeChartOptions', 'Stock Volume', this.processedData.dailyStockVolumes)
  }

  renderChart() {
    const width = window.innerWidth - 10;
    const height = (window.innerWidth - 40) / 3 * 2.5;
    const series = [];
    if (this.capitalInvested !== 2) {
      series.push({
        type: 'area',
        name: 'USD',
        data: this.processedData.dailyInvested,
        color: 'rgba(0,0,0,0.5)',
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, `grey`],
            [1, Highcharts.color(`rgb(255 255 255)`).setOpacity(0.1).get('rgba')]
          ]
        },
      });
    }
    console.log(this.processedData);
    if (this.capitalInvested !== 1) {
      series.push({
        type: 'area',
        name: 'USD',
        data: this.processedData.dailyPrices,
        color: `rgb(${this.chartColor})`
      });
    }

    this.chartOptions = {
      chart: {
        // width: 300,
        width: width < 580 ? width : 580,
        height: height < 580 ? height : 380,
        // zoomType: 'x',
        // spacingBottom: 0,
        // spacingTop: 0,
        // spacingLeft: 0,
        // spacingRight: 0,
        // marginBottom: 0,
        // marginTop: 0,
        // marginLeft: 0,
        // marginRight: 0,
      },
      colors: [`rgb(${this.chartColor})`, `rgba(${this.chartColor} / 20%)`],
      title: {
        // text: 'USD to EUR exchange rate over time'
        text: ''
      },
      subtitle: {
        // text: document.ontouchstart === undefined ?
        //   'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        text: `<span style="color: rgba(${this.chartColor})">Capital Invested  </span> <div style="color: var(--ion-text-color);"><div style="text-transform: lowercase; margin: 0 5px">vs</div> Not Invested </div>`
      },
      xAxis: {
        type: 'datetime',
        // minPadding: 0,
        // maxPadding: 0,
        labels: {
          style: {
            color: 'var(--ion-text-color)'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          style: {
            color: 'var(--ion-text-color)'
          }
        }
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, `rgb(${this.chartColor})`],
              [1, Highcharts.color(`rgba(${this.chartColor} / 20%)`).setOpacity(0.5).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: '500'
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      credits: false,
      series: series
    };
  }

  renderGenericChart(chartName, title, data) {
    const width = window.innerWidth - 10;
    const height = (window.innerWidth - 40) / 3 * 2.5;
    let series = []
    series.push({
      type: 'area',
      name: 'USD',
      data: data,
      color: `rgb(${this.chartColor})`
    });

    this[chartName] = {
      chart: {
        width: width < 580 ? width : 580,
        height: height < 580 ? height : 380,
      },
      colors: [`rgb(${this.chartColor})`, `rgba(${this.chartColor} / 20%)`],
      title: {
        text: ''
      },
      subtitle: {
        text: `<div style="color: var(--ion-text-color)">${title}</div>`
      },
      xAxis: {
        type: 'datetime',
        // minPadding: 0,
        // maxPadding: 0,
        labels: {
          style: {
            color: 'var(--ion-text-color)'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          style: {
            color: 'var(--ion-text-color)'
          }
        }
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, `rgb(${this.chartColor})`],
              [1, Highcharts.color(`rgba(${this.chartColor} / 20%)`).setOpacity(0.5).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: '500'
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      credits: false,
      series: series
    };
  }

  async openChartHistory() {
    const modal = await this.modalController.create({
      component: StockSimulationComponent,
      cssClass: 'stock-modal',
      componentProps: {transactionHistory: this.processedData.transactionHistory}
    });
    return await modal.present();
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
