import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { StockItemPreviewComponent } from './stock-item-preview.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule, 
    IonicModule, 
    HighchartsChartModule,
  ],
  declarations: [StockItemPreviewComponent],
  exports: [StockItemPreviewComponent]
})
export class StockItemPreviewModule {}
