import { StockListQuote } from './../services/stockdata.service';
import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/dark-unica';
theme(Highcharts);

@Component({
  selector: 'app-stock-item-preview',
  templateUrl: './stock-item-preview.component.html',
  styleUrls: ['./stock-item-preview.component.scss'],
})
export class StockItemPreviewComponent implements OnInit {

  Highcharts = Highcharts;
  @Input() selectedStock: StockListQuote;
  @Input() isVertical: boolean;
  @Input() chartData: any;

  constructor() { }

  ngOnInit() {}

}
