import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  serverAPI = environment.apiUrl;

  constructor(private http: HttpClient) { }

  infoUser(userToken?, accessToken?) {
      let headers: any = {
          'Content-Type': 'application/json',
      };
      if (userToken) {
          headers.token = userToken;
      }
      if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`;
      }

      return this.http.get(this.serverAPI + '/users/me', { headers } ).toPromise();
  }

  loginUser(email, password) {
      return this.http.post(`${this.serverAPI}/users/login`, {email, password}).toPromise();
  }

  signupUser(username, email, password) {
      return this.http.post(`${this.serverAPI}/users/signup`, {username, email, password}).toPromise();
  }
}
