import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {

    // document.body.classList.toggle('light', true);

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    document.body.classList.toggle(prefersDark.matches ? 'dark' : 'light', prefersDark.matches);

    this.route.queryParams.subscribe(params => {
      const accessToken = params['token'];
      if (accessToken) {
        this.userService.infoUser(null, accessToken).then((userData: any) => {
          const currentUser = {
            user: userData.code,
            accessToken,
            email: userData.email,
            role: userData.role,
            provider: userData.provider ? userData.provider : null,
            username: userData.username ? userData.username : null,
            photo: userData.photo ? userData.photo : null,
          };
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
  
          // location.href = returnUrl;
        }).catch((err) => {
          console.log(err);
        });
      }
    });


    // let returnUrl = this.route.snapshot.queryParams.returnUrl;
    // // console.log(this.returnUrl)

    // // console.log(this.route.snapshot.queryParams.returnUrl.split('?token=')[0])
    // const splittedReturnUrl = this.route.snapshot.queryParams.returnUrl.split('?token=');
    // if (splittedReturnUrl[1]) {

    //   // removing facebook security fragment
    //   const accessToken = splittedReturnUrl[1].replace('#_=_', '');
    //   returnUrl = location.origin + splittedReturnUrl[0];
    //   this.userService.infoUser(null, accessToken).then((userData: any) => {
    //     const currentUser = {
    //       user: userData.code,
    //       accessToken,
    //       email: userData.email,
    //       role: userData.role,
    //       provider: userData.provider ? userData.provider : null,
    //       username: userData.username ? userData.username : null,
    //       photo: userData.photo ? userData.photo : null,
    //     };
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('currentUser', JSON.stringify(currentUser));

    //     location.href = returnUrl;
    //   }).catch((err) => {
    //     console.log(err);
    //   });
    // }
  }
}
