import { StockItemPreviewModule } from './stock-item-preview/stock-item-preview.module';
import { StockSimulationComponent } from './stock-simulation/stock-simulation.component';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HighchartsChartModule } from 'highcharts-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StockAnalysisDetailsComponent } from './stock-analysis-details/stock-analysis-details.component';
import { JwtInterceptor } from './services/jwt.interceptor';
import { ErrorInterceptor } from './services/error.interceptor';
import { StockNewsPreviewModule } from './stock-news-preview/stock-news-preview.module';

@NgModule({
  declarations: [AppComponent, StockAnalysisDetailsComponent, StockSimulationComponent],
  entryComponents: [StockAnalysisDetailsComponent, StockSimulationComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(
      { mode: 'md' }
    ), 
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    StockItemPreviewModule,
    StockNewsPreviewModule,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
