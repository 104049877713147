import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-stock-simulation',
  templateUrl: './stock-simulation.component.html',
  styleUrls: ['./stock-simulation.component.scss'],
})
export class StockSimulationComponent implements OnInit {

  @Input() transactionHistory;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  
}
